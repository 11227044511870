import { Validator } from 'simple-vue-validator';

export default class Check360Url {
  constructor(url, $i18n) {
    this.url = url;
    this.$i18n = $i18n;
  }

  get isValidFunc() {
    const acceptedDomains = [
      'rumahvirtual360.com',
      '360.goterest.com',
      'tourmkr.com',
      'kuula.co',
      'momento360.com',
      'panoraven.com',
      'www.panopedia.com',
      '360cities.net',
      'matterport.com',
      '3dvista.com',
      'vr-360-tour.com',
      'eyespy360.com',
    ];

    const validationFunc = async () => {
      if (Validator.isEmpty(this.url)) {
        return;
      }
      try {
        const urlObj = new URL(this.url);
        const hostname = urlObj.hostname;
        if (acceptedDomains.some(domain => hostname.includes(domain))) {
          return;
        }
        return this.$i18n.t('errors.360Url.invalid');
      } catch (e) {
        return this.$i18n.t('errors.360Url.invalid');
      }
    };

    return validationFunc;
  }
}
