<template>
  <div class="listing--form-container">
    <rent-buy-switch @changeType="changeTypeHandler" />
    <div class="form--group" v-if="propertyNameOptions.length > 0">
      <div class="left--col">
        <div class="d-flex align-items-center">
          <label for="property-list-field">
            <span>{{ $t('addListing.propertyDraft') }}</span>
          </label>
          <img src="@/assets/img/icons/info.svg" class="info--icon" id="draftPropertyInfo" />
        </div>
        <PopOverListingForm target="draftPropertyInfo" :content="$t('addListing.hint.draft')" />
      </div>
      <div class="right--col">
        <multiselect
          id="property-list-field"
          name="property_uuid"
          :value="propertyListLocalValue"
          @input="propertyListLocalValueChanged"
          :options="propertyNameOptions"
          :searchable="true"
          :allowEmpty="true"
          :close-on-select="true"
          track-by="uuid"
          label="property_name"
          :show-labels="true"
          :placeholder="$t('addListing.draftPlaceholder')"
        >
        </multiselect>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('propertyName') }">
      <div class="left--col">
        <label for="property-name-field">
          <span>{{ $t('addListing.propDraftName') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input
          id="property-name-field"
          v-model="propertyName"
          :placeholder="$t('addListing.propDraftPlaceholder')"
          name="full_name"
          class="basic--input"
        />
        <i v-show="validation.isValidating('propertyName')" class="ion-load-c"></i>
        <span class="val-error" v-if="validation.hasError('propertyName')">
          {{ validation.firstError('propertyName') }}
        </span>
      </div>
    </div>
    <property-type-section ref="propertyTypeSection" />
    <map-section ref="mapSection" />
    <listing-form-transition>
      <div v-show="openAddressSection">
        <div class="form--group" :class="{ 'has-error': validation.hasError('addressLine1') }">
          <div class="left--col">
            <label for="address-line-1-field">
              <span>{{ $t('general.address1') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              v-model="addressLine1"
              :placeholder="$t('addListing.placeholder.address1')"
              id="address-line-1-field"
              name="address_line_1"
              class="basic--input"
              type="text"
            />
            <span class="val-error" v-if="validation.hasError('addressLine1')">{{
              validation.firstError('addressLine1')
            }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('addressLine2') }">
          <div class="left--col">
            <label for="address-line-2-field">
              <span>{{ $t('general.address2') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              v-model="addressLine2"
              :placeholder="$t('addListing.placeholder.address2')"
              id="address-line-2-field"
              name="address_line_1"
              class="basic--input"
              type="text"
              rows="4"
            />
            <span class="val-error" v-if="validation.hasError('addressLine2')">{{
              validation.firstError('addressLine2')
            }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('postalCode') }">
          <div class="left--col">
            <label for="address-line-1-field">
              <span>{{ $t('general.postalCode') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              v-model="postalCode"
              :placeholder="$t('addListing.placeholder.postalCode')"
              id="postal-code-field"
              name="postal_code"
              class="basic--input"
              type="text"
            />
            <span class="val-error" v-if="validation.hasError('postalCode')">{{
              validation.firstError('postalCode')
            }}</span>
          </div>
        </div>
      </div>
    </listing-form-transition>
    <listing-form-transition>
      <amenities-section v-show="openAmenities" ref="amenitiesSection" />
    </listing-form-transition>
    <listing-condition-switch v-if="listingType === 'S'" />
    <listing-form-transition>
      <property-ownership-section
        v-show="listingType === 'R' || (openOwnership && !isConditionPrimaryValue)"
        ref="propertyOwnershipSection"
      />
    </listing-form-transition>
    <listing-form-transition>
      <tenure-type-section v-show="openBasicInfo && allowTenure" ref="tenureTypeSection" />
    </listing-form-transition>
    <listing-form-transition>
      <div v-show="openDetail">
        <div class="form--group" :class="{ 'has-error': validation.hasError('aboutProperty') }">
          <div class="left--col">
            <label for="description-field">
              <span>{{ $t('addListing.description') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              id="description-field"
              v-model="aboutProperty"
              :placeholder="$t('addListing.placeholder.description')"
              name="about_property"
              class="basic--input"
              type="text"
              rows="4"
            />
            <span class="val-error" v-if="validation.hasError('aboutProperty')">{{
              validation.firstError('aboutProperty')
            }}</span>
          </div>
        </div>
        <photo-section ref="photoSection" />
        <floor-plan-photo-section ref="floorPlanPhotoSection" />
        <div class="form--group" :class="{ 'has-error': validation.hasError('youtubeUrl') }">
          <div class="left--col">
            <div class="d-flex align-items-center">
              <label for="youtube-url-field">
                <span>{{ $t('addListing.video') }}</span>
              </label>
              <img src="@/assets/img/icons/info.svg" class="info--icon" id="youtubeUrlInfo" />
              <PopOverListingForm
                target="youtubeUrlInfo"
                :content="$t('addListing.hint.youtubeUrl')"
              />
            </div>
          </div>
          <div class="right--col">
            <input
              v-model="youtubeUrl"
              id="youtube-url-field"
              name="youtube_url"
              class="basic--input"
              type="text"
              placeholder="Insert Url Video Here"
            />
            <span class="val-error" v-if="validation.hasError('youtubeUrl')">{{
              validation.firstError('youtubeUrl')
            }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('panoEmbedUrl') }">
          <div class="left--col">
            <label for="pano-embed-field">
              <span>{{ $t('addListing.360url') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              id="pano-embed-field"
              v-model="panoEmbedUrl"
              name="pano_embed_url"
              class="basic--input"
              type="text"
              placeholder="Insert Url Video Here"
            />
            <span class="val-error" v-if="validation.hasError('panoEmbedUrl')">{{
              validation.firstError('panoEmbedUrl')
            }}</span>
          </div>
        </div>
        <hr class="separator" />
        <occupied-switch />
        <div>
          <div
            v-show="listingType === 'R'"
            class="form--group"
            :class="{ 'has-error': validation.hasError('rentType') }"
          >
            <div class="left--col">
              <label for="rent-type-id-field">
                <span>{{ $t('addListing.rentType') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <multiselect
                id="rent-type-id-field"
                name="rentType"
                v-model="rentType"
                :options="rentTypeOptionsId"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                :custom-label="rentTypeLabel"
                :class="validation.hasError('rentType') ? 'is-invalid' : ''"
                :placeholder="$t('addListing.placeholder.rentType')"
              >
              </multiselect>
              <span class="val-error" v-if="validation.hasError('rentType')">{{
                validation.firstError('rentType')
              }}</span>
            </div>
          </div>
          <div
            v-show="listingType === 'S'"
            class="form--group"
            :class="{ 'has-error': validation.hasError('developerName') }"
          >
            <div class="left--col">
              <label for="developer-name-field">
                <span>{{ $t('addListing.developerName') }}</span>
              </label>
            </div>
            <div class="right--col">
              <input
                id="developer-name-field"
                v-model="developerName"
                name="youtube_url"
                class="basic--input"
                type="text"
                :placeholder="$t('addListing.placeholder.developerName')"
              />
              <span class="val-error" v-if="validation.hasError('developerName')">{{
                validation.firstError('developerName')
              }}</span>
            </div>
          </div>
          <div
            class="form--group"
            :class="{ 'has-error': validation.hasError('sizeInSquareMeters') }"
          >
            <div class="left--col">
              <label for="sizesquaremeter-field">
                <span>{{ $t('addListing.buildingSize') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <div class="input--group">
                <input
                  id="sizesquaremeter-field"
                  v-model="sizeInSquareMeters"
                  name="size_in_square_meters"
                  class="basic--input size--input"
                  type="text"
                />
                <div class="append--right">
                  sqft
                </div>
              </div>
              <span class="val-error" v-if="validation.hasError('sizeInSquareMeters')">{{
                validation.firstError('sizeInSquareMeters')
              }}</span>
            </div>
          </div>

          <div
            v-show="listingType === 'R'"
            class="form--group"
            :class="{ 'has-error': validation.hasError('minRentDuration') }"
          >
            <div class="left--col">
              <label for="minRentDuration-field">
                <span>{{ $t('addListing.minRentDuration') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <div class="input--group">
                <cleave
                  id="minRentDuration-field"
                  class="basic--input"
                  type="text"
                  name="min_rent_duration"
                  v-model="minRentDuration"
                  :options="cleaveOption"
                />
                <div class="append--right">
                  month(s)
                </div>
              </div>
              <span class="val-error" v-if="validation.hasError('minRentDuration')">{{
                validation.firstError('minRentDuration')
              }}</span>
            </div>
          </div>
          <div
            v-show="listingType === 'R'"
            class="form--group"
            :class="{ 'has-error': validation.hasError('bookingFeeMonthAmount') }"
          >
            <div class="left--col">
              <label for="bookingFeeMonthAmount-field">
                <span>{{ $t('general.bookingFee') }}</span>
                <span class="required">*</span>
              </label>
            </div>
            <div class="right--col">
              <multiselect
                id="bookingFeeMonthAmount-field"
                name="bookingFeeMonthAmount"
                v-model="bookingFeeMonthAmount"
                :custom-label="bookingFeeMonthAmountLabel"
                :options="[1, 2, 3]"
                :allowEmpty="false"
                :close-on-select="true"
                :class="validation.hasError('rentType') ? 'is-invalid' : ''"
                :placeholder="$t('addListing.placeholder.bookingFeeMonthAmount')"
              >
              </multiselect>
              <span class="val-error" v-if="validation.hasError('bookingFeeMonthAmount')">{{
                validation.firstError('bookingFeeMonthAmount')
              }}</span>
            </div>
          </div>
          <view-to-offer-switch v-if="listingType === 'R'" />
          <listing-form-transition>
            <rent-price-section
              ref="rentPriceSection"
              v-show="listingType === 'R' && !viewToOffer"
            />
          </listing-form-transition>
          <discount-rent-section
            v-if="listingType === 'R' && allowListingDiscount"
            ref="discountSectionRentRef"
          />
          <detail-unit-section ref="detailUnitSection" />
          <done-estimation-section v-show="listingType === 'S'" />
          <view-to-offer-switch v-if="listingType === 'S'" />
          <listing-form-transition>
            <div
              v-show="listingType === 'S' && !viewToOffer"
              class="form--group"
              :class="{ 'has-error': validation.hasError('sellPrice') }"
            >
              <div class="left--col">
                <label for="address-line-1-field">
                  <span>{{ $t('addListing.sellPrice') }}</span>
                </label>
              </div>
              <div class="right--col">
                <div class="input--group">
                  <div class="append--left">
                    S$
                  </div>
                  <cleave
                    class="basic--input price--input"
                    type="text"
                    name="sell_price"
                    v-model="sellPrice"
                    :options="cleaveOption"
                    ref="price"
                  />
                </div>
                <span class="val-error" v-if="validation.hasError('sellPrice')">{{
                  validation.firstError('sellPrice')
                }}</span>
              </div>
            </div>
          </listing-form-transition>
          <discount-sell-section v-if="allowListingDiscount" ref="discountSectionSellRef" />
          <div v-show="listingType === 'R'" class="form--group available--from">
            <div class="left--col">
              <label for="address-line-1-field">
                <span>{{ $t('addListing.availableFrom') }}</span>
              </label>
            </div>
            <div class="right--col">
              <date-picker
                v-model="availableFrom"
                :minDate="new Date()"
                format="dd/MM/yyyy"
              ></date-picker>
            </div>
          </div>

          <!--          <facilities-section ref="facilitiesSection" />-->
          <!--          <other-facilities-section ref="otherFacilitiesSection" />-->
          <!--          <operational-hour-section ref="operationalHourSection" />-->
        </div>
      </div>
    </listing-form-transition>
    <div class="bottom--container">
      <button
        v-if="!isPublished"
        class="btn btn-primary--outline bottom--button"
        @click="saveDraft"
      >
        {{ $t('addListing.btn.saveToDraft') }}
      </button>
      <button class="btn btn-primary bottom--button" @click="next">
        {{ $t('addListing.btn.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';
import HelperMixin from '@/mixins/helpers';
const DatePicker = () => import('@/components/utils/date-picker');

const RentBuySwitch = () => import('@/components/listing-form/rent-buy-switch.vue');
const RentPriceSection = () =>
  import('@/components/listing-form/sect1/rent-price/rent-prices-section.vue');
const ListingConditionSwitch = () =>
  import('@/components/listing-form/sect1/listing-condition-switch.vue');
const ViewToOfferSwitch = () => import('@/components/listing-form/sect1/view-to-offer-switch.vue');
const OccupiedSwitch = () => import('@/components/listing-form/sect1/occupied-switch.vue');
const PropertyTypeSection = () =>
  import('@/components/listing-form/sect1/property-type-section.vue');
const PropertyOwnershipSection = () =>
  import('@/components/listing-form/sect1/property-ownership-section.vue');
const AmenitiesSection = () =>
  import('@/components/listing-form/sect1/amenities/amenities-section.vue');

const MapSection = () => import('@/components/listing-form/sect1/map/map-section.vue');
const PhotoSection = () => import('@/components/listing-form/sect1/photo-section.vue');
const FloorPlanPhotoSection = () =>
  import('@/components/listing-form/sect1/floor-plan-photo-section.vue');

const DetailUnitSection = () => import('@/components/listing-form/sect1/detail-unit-section.vue');
const TenureTypeSection = () => import('@/components/listing-form/sect1/tenure-type-section.vue');
const DoneEstimationSection = () =>
  import('@/components/listing-form/sect1/done-estimation-section.vue');
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import CheckYoutubeUrlClass from '@/validations/checkYoutubeUrl';
import Cleave from 'vue-cleave-component';
// const FacilitiesSection = () =>
import('@/components/listing-form/sect2/facility/facilities-section.vue');
const DiscountRentSection = () =>
  import('@/components/listing-form/sect1/discount/discount-rent-section.vue');
const DiscountSellSection = () =>
  import('@/components/listing-form/sect1/discount/discount-sell-section.vue');
import Check360UrlClass from '@/validations/check360Url';

// const OperationalHourSection = () =>
import('@/components/listing-form/sect2/operational-hour/operational-hour-section.vue');
// const OtherFacilitiesSection = () =>
import('@/components/listing-form/sect2/other-facilities/other-faciltiies-section.vue');
import ListingFormTransition from '@/components/listing-form/listing-form-transition';
import CheckPropertyNameExistClass from '@/validations/checkPropertyNameExist';

export default {
  name: 'sect1-listing-form',
  mixins: [HelperMixin],
  components: {
    Cleave,
    OccupiedSwitch,
    DatePicker,
    ListingFormTransition,
    PopOverListingForm,
    TenureTypeSection,
    ViewToOfferSwitch,
    DiscountRentSection,
    DiscountSellSection,
    // FacilitiesSection,
    // OtherFacilitiesSection,
    // OperationalHourSection,
    DetailUnitSection,
    DoneEstimationSection,
    RentBuySwitch,
    RentPriceSection,
    ListingConditionSwitch,
    Multiselect,
    PropertyTypeSection,
    PropertyOwnershipSection,
    AmenitiesSection,
    MapSection,
    PhotoSection,
    FloorPlanPhotoSection,
  },
  data() {
    return {
      propertyListLocalValue: null,
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
    };
  },
  validators: {
    sellPrice(value) {
      if (this.listingType === 'R' || this.viewToOffer) return null;
      return Validator.value(value).required(this.$i18n.t('errors.sellPrice.null'));
    },
    postalCode(value) {
      return Validator.value(value).required(this.$i18n.t('errors.postal_code.required'));
    },
    landSize(value) {
      return Validator.value(value).between(
        1,
        100000,
        this.$i18n.t('errors.addListing.lSize.between', {
          minChar: 1,
          maxChar: '100.000',
        }),
      );
    },
    developerName(value) {
      return Validator.value(value).maxLength(
        50,
        this.$i18n.t('errors.developer_name.max', { maxChar: 50 }),
      );
    },
    youtubeUrl(value) {
      const checkYoutubeUrl = new CheckYoutubeUrlClass(value, this.$i18n);
      return Validator.value(value).custom(checkYoutubeUrl.isValidFunc);
    },
    panoEmbedUrl(value) {
      const check360Url = new Check360UrlClass(value, this.$i18n);
      return Validator.value(value).custom(check360Url.isValidFunc);
    },
    addressLine1(value) {
      return Validator.value(value).lengthBetween(
        3,
        250,
        this.$i18n.t('errors.addListing.address1.between', { minChar: 3, maxChar: 250 }),
      );
    },
    addressLine2(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.address2.required'))
        .lengthBetween(
          3,
          100,
          this.$i18n.t('errors.addListing.address2.between', {
            minChar: 3,
            maxChar: 100,
          }),
        );
    },
    aboutProperty(value) {
      return Validator.value(value).maxLength(
        2000,
        this.$i18n.t('errors.addListing.description.max', { maxChar: 2000 }),
      );
    },
    rentType(value) {
      if (this.listingType === 'S') return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.rentType.required'));
    },

    sizeInSquareMeters(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.bSize.required'))
        .greaterThanOrEqualTo(1);
    },
    minRentDuration(value) {
      if (this.listingType === 'R') {
        return Validator.value(value)
          .required(this.$i18n.t('errors.addListing.minRentDuration.required'))
          .greaterThanOrEqualTo(1);
      } else {
        return Validator.value(value);
      }
    },
    'bookingFeeMonthAmount, minRentDuration': function(bookingFeeMonthAmount, minRentDuration) {
      if (this.listingType === 'R') {
        return Validator.value(bookingFeeMonthAmount)
          .required(this.$i18n.t('errors.addListing.bookingFeeMonthAmount.required'))
          .between(1, 3)
          .custom(() => {
            if (bookingFeeMonthAmount > minRentDuration) {
              return 'Booking Fee Month Amount must be less than Minimum Rent Duration';
            }
          });
      } else {
        return Validator.value(bookingFeeMonthAmount);
      }
    },
    // bookingFeeMonthAmount(value) {
    //   if (this.listingType === 'R') {
    //     return Validator.value(value)
    //       .required(this.$i18n.t('errors.addListing.bookingFeeMonthAmount.required'))
    //       .between(1, 3)
    //       .custom(() => {
    //         if (value > this.minRentDuration) {
    //           return 'Booking Fee Month Amount must be less than Minimum Rent Duration';
    //         }
    //       });
    //   } else {
    //     return Validator.value(value);
    //   }
    // },
    'propertyName,propertyUuidForm': {
      debounce: 500, // in milliseconds
      validator: function(propertyName, propUuid) {
        const checkPropertyNameExist = new CheckPropertyNameExistClass(
          propertyName,
          propUuid,
          this.$http,
          this.$i18n,
        );
        return (
          Validator.value(propertyName)
            .required(this.$i18n.t('errors.addListing.propertyName.required'))
            // .maxLength(150, this.$i18n.t('errors.addListing.propertyName.max', { maxChar: 100 }))
            .custom(checkPropertyNameExist.isValidFunc)
        );
      },
    },
  },
  computed: {
    ...mapGetters({
      allowTenure: 'v2/listingForm/sect1/basicInfo/allowTenure',
      selectedPropertyType: 'v2/listingForm/sect1/location/selectedPropertyType',
      selectedExistingPropertyUuid: 'v2/listingForm/sect1/location/selectedPropertyUuid',
      propertyNameOptions: 'v2/listingForm/sect1/location/propertyListOptions',
      selectedPropertyTypeName: 'v2/listingForm/sect1/location/selectedPropertyTypeName',
      selectedPropertyName: 'v2/listingForm/sect1/location/selectedPropertyName',
      rentTypeOptionsId: 'v2/listingForm/sect1/basicInfo/rentTypeOptionsId',
      selectedRentTypeText: 'v2/listingForm/sect1/basicInfo/selectedRentTypeText',
      rentPeriods: 'v2/listingForm/sect1/basicInfo/rentPeriodList',
      allowIsNegotiable: 'v2/listingForm/sect1/basicInfo/isNegotiableAllowed',
      selectedRentType: 'v2/listingForm/sect1/basicInfo/selectedRentTypeId',
      selectedRentPeriodIds: 'v2/listingForm/sect1/basicInfo/selectedRentPeriodIds',
      allow360EmbedListing: 'global/allow360EmbedListing',
      allowListingDiscount: 'global/allowListingDiscount',
      selectedProperty: 'v2/listingForm/sect1/location/selectedProperty',
      propertyUuidForm: 'v2/listingForm/sect1/location/propertyUuidForm',
    }),
    ...mapState({
      openAddressSection: state => state.v2.listingForm.sect1.openAddressSection,
      viewToOffer: state => state.v2.listingForm.sect1.basicInfo.viewToOffer,
      sect2Touched: state => state.v2.listingForm.sect2.isTouched,
      propertyOwnershipTypeList: state =>
        state.v2.listingForm.sect1.location.propertyOwnershipTypeList,
      amenities: state => state.v2.listingForm.sect1.location.amenities,
      isConditionPrimaryValue: state => state.v2.listingForm.sect1.basicInfo.isConditionPrimary,
      formListing: state => state.v2.listingForm.form,
      locationTouched: state => state.v2.listingForm.sect1.location.isTouched,
      basicInfoTouched: state => state.v2.listingForm.sect1.basicInfo.isTouched,
      // openMap: state => state.v2.listingForm.sect1.openMap,
      selectedTenureType: state => state.v2.listingForm.sect1.basicInfo.selectedTenureType,
      openDetail: state => state.v2.listingForm.sect1.openDetail,
      openOwnership: state => state.v2.listingForm.sect1.openOwnership,
      openAmenities: state => state.v2.listingForm.sect1.openAmenities,
      openMedia: state => state.v2.listingForm.sect1.openMedia,
      openBasicInfo: state => state.v2.listingForm.sect1.openBasicInfo,
      listingType: state => state.v2.listingForm.listingType,
      sellPrice: state => state.v2.listingForm.sect1.basicInfo.sellPrice,
      isPublished: state => state.v2.listingForm.isPublished,
    }),
    postalCode: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.postalCode;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/location/SET_POSTAL_CODE', value);
      },
    },
    availableFrom: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.availableFrom;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_AVAILABLE_FROM', val);
      },
    },
    minRentDuration: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.minRentDuration;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_MIN_RENT_DURATION', val);
      },
    },
    bookingFeeMonthAmount: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.bookingFeeMonthAmount;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_BOOKING_FEE_MONTH_AMOUNT', val);
      },
    },
    youtubeUrl: {
      get() {
        return this.$store.state.v2.listingForm.sect1.media.youtubeUrl;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/media/UPDATE_YOUTUBE_URL', val);
      },
    },
    sizeInSquareMeters: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.sizeInSquareMeters;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/CHANGE_SIZEINSQUAREMETERS', value);
      },
    },
    landSize: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.landSize;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_LAND_SIZE', value);
      },
    },
    developerName: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.developerName;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DEVELOPER_NAME', value);
      },
    },
    sellPrice: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.sellPrice;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SELL_PRICE', value);
      },
    },
    showDiscount: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.showDiscount;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SHOW_DISCOUNT', value);
      },
    },
    isNegotiableValue: {
      get() {
        return this.$store.getters['v2/listingForm/sect1/basicInfo/isNegotiableValue'];
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/basicInfo/CHANGE_ISNEGOTIABLE', value);
      },
    },
    rentType: {
      get() {
        return this.$store.getters['v2/listingForm/sect1/basicInfo/selectedRentTypeId'];
      },
      set(value) {
        let self = this;
        self.$store.commit('v2/listingForm/sect1/basicInfo/SET_SELECTED_RENT_TYPE', value);
      },
    },

    // selectedPropertyName:{
    //     get(){
    //         return this.$store.getters['v2/listingForm/sect1/location/getSelectedPropertyName'];
    //     },
    //     set(newValue){
    //         const oldValue = this.selectedPropertyTypeName
    //         console.log('old',oldValue,'new',newValue)
    //         if(oldValue!==newValue){
    //         // if(this.locationTouched && this.basicInfoTouched){
    //             swal({
    //                 title: 'Peringatan',
    //                 text:  'Kalau anda melakukan ini, data di basic info akan hilang, lanjut?',
    //                 // title: Vue.i18n.translate('mylisting.failedPublishTitle'),
    //                 // text:  Vue.i18n.translate('mylisting.failedPublishMessage'),
    //                 type: 'warning',
    //                 showCancelButton: true,
    //                 confirmButtonColor: '#3085d6',
    //                 cancelButtonColor: '#d33',
    //                 confirmButtonText: "Ya",
    //                 allowOutsideClick: false
    //             }).then(()=>{
    //                 console.log('ya',this)
    //                 this.changePropertyUuid(newValue);

    //             }, (dismiss)=> {
    //                 // dismiss can be 'cancel', 'overlay',
    //                 // 'close', and 'timer'
    //                 if (dismiss === 'cancel') {
    //                     console.log('tidak')
    //                     this.selectedPropertyName=oldValue
    //                 }
    //             });
    //         } else {
    //             this.$nextTick(()=>{
    //                 this.changePropertyUuid(newValue);
    //             })
    //         }

    //     }
    // },

    propertyName: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.propertyName;
      },
      set(value) {
        // propNameExistValidation(value);
        // console.log('PROP MA,E', this.validation);
        this.$store.commit('v2/listingForm/sect1/location/SET_PROPERTY_NAME', value);
      },
    },
    selectedPropertyOwnershipType: {
      get() {
        return this.$store.getters['v2/listingForm/sect1/location/selectedPropertyOwnershipType'];
      },
      set(value) {
        return this.$store.commit(
          'v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_OWNERSHIP_TYPE',
          value,
        );
      },
    },
    addressLine1: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.addressLine1;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/location/CHANGE_ADDRESSLINE1', value);
      },
    },
    addressLine2: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.addressLine2;
      },
      set(value) {
        if (!this.openAmenities) {
          this.$store.commit('v2/listingForm/sect1/SET_OPEN_AMENITIES', true);
        }
        // if (!this.openMap) {
        //   this.$store.commit('v2/listingForm/sect1/SET_OPEN_MAP', true);
        // }
        if (!this.openMedia) {
          this.$store.commit('v2/listingForm/sect1/SET_OPEN_MEDIA', true);
        }
        return this.$store.commit('v2/listingForm/sect1/location/CHANGE_ADDRESSLINE2', value);
      },
    },
    aboutProperty: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.aboutProperty;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/location/CHANGE_ABOUTPROPERTY', value);
      },
    },
    latitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.latitude;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/location/CHANGE_LATITUDE', value);
      },
    },
    longitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.longitude;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect1/location/CHANGE_LONGITUDE', value);
      },
    },
    currentStep: {
      get() {
        return this.$store.state.v2.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('v2/listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
    panoEmbedUrl: {
      get() {
        return this.$store.state.v2.listingForm.sect1.media.panoEmbedUrl;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/media/UPDATE_PANO_EMBED_URL', val);
      },
    },
  },
  watch: {
    propertyListLocalValue(value) {
      this.propUuidInput(value);
    },
    showDiscount(newVal) {
      if (newVal === false) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DISCOUNT_PERCENTAGE', null);
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DISCOUNT_PRICE', null);
      }
    },
  },
  methods: {
    bookingFeeMonthAmountLabel(value) {
      if (value === 1) {
        return '1 Month';
      } else {
        return `${value} Months`;
      }
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.rentPriceSection.validate());
      validationArray.push(this.$refs.tenureTypeSection.validate());
      validationArray.push(this.$refs.propertyTypeSection.validate());

      validationArray.push(this.$refs.detailUnitSection.validate());

      validationArray.push(this.$refs.propertyOwnershipSection.validate());

      validationArray.push(this.$refs.amenitiesSection.validate());

      validationArray.push(this.$refs.photoSection.validate());

      validationArray.push(this.$refs.detailUnitSection.validate());

      validationArray.push(this.$refs.mapSection.validate());

      validationArray.push(this.$validate());
      if (this.allowListingDiscount) {
        if (this.listingType === 'R') {
          validationArray.push(this.$refs.discountSectionRentRef.validate());
        } else {
          validationArray.push(this.$refs.discountSectionSellRef.validate());
        }
      }
      return Promise.all(validationArray).then(function(results) {
        if (
          results.filter(function(result) {
            console.log('mana yg salah', result);
            return !result;
          }).length === 0
        ) {
          console.log('trueeeee');
          return true;
        }
        console.log('falseeee');
        return false;
      });
    },
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    async saveDraft() {
      try {
        this.setLoading(true);

        const isValid = await this.$refs.propertyTypeSection.validate();
        if (isValid) {
          const postDataSuccess = await this.$store.dispatch('v2/listingForm/saveDraft');
          if (postDataSuccess) {
            this.$modal.show('modal-success-listing-form');
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
      } finally {
        this.setLoading(false);
      }
    },
    async save() {
      try {
        this.setLoading(true);
        const isValid = await this.validate();
        if (isValid) {
          const postDataSuccess = await this.$store.dispatch('v2/listingForm/postData');
          console.log('savenya', postDataSuccess);
          if (postDataSuccess) {
            this.$store.commit('v2/listingForm/sect1/location/SET_IS_TOUCHED', true);
          }
          return postDataSuccess;
        } else {
          this.scrollToErrorSection(this.$el);
        }
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async next() {
      const resultSave = await this.save();
      if (resultSave) {
        this.scrollToTop();
        this.currentStep += 1;
        this.$store.commit('v2/listingForm/sect2/SET_IS_TOUCHED', true);
      }
    },
    changeTypeHandler() {
      this.propertyListLocalValue = null;
      this.validation.reset();
    },
    rentTypeChanged(data) {
      let self = this;
      self.$store.commit('v2/listingForm/sect1/basicInfo/SET_SELECTED_RENT_TYPE', data);
    },
    rentTypeLabel(data) {
      return this.$store.getters['v2/listingForm/sect1/basicInfo/rentTypeOptionLabel'](data);
    },
    propUuidInput(value) {
      // console.log(
      //   "local",
      //   this.propertyListLocalValue,
      //   "vuex",
      //   this.selectedPropertyName
      // );
      value = JSON.parse(JSON.stringify(value));
      console.log('awweeee', value);
      const propertyLocalValueReal = JSON.parse(JSON.stringify(this.propertyListLocalValue));

      console.log('propertyListLocalValue 1', this.propertyListLocalValue);
      console.log('propertyListLocalValue 2', propertyLocalValueReal);
      console.log('propertyListLocalValue 3', this.selectedProperty);
      if (propertyLocalValueReal) {
        console.log('if 1');
        if (propertyLocalValueReal?.uuid !== this.selectedProperty?.uuid) {
          if (
            this.selectedRentPeriodIds.length !== 0 ||
            this.selectedRentType ||
            this.selectedTenureType
          ) {
            this.$swal({
              title: this.$i18n.t('addListing.propertyNameChange.title'),
              text: this.$i18n.t('addListing.propertyNameChange.message'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: this.$i18n.t('addListing.continue'),
              cancelButtonText: this.$i18n.t('addListing.cancel'),
              allowOutsideClick: false,
            }).then(result => {
              if (result.value === true) {
                this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyDefaultValue');
                this.$store.commit('v2/listingForm/sect2/detail/RESTORE_DEFAULT_ADD_STATE');
                this.changePropertyUuid(value.uuid);
              } else {
                this.$nextTick(() => {
                  this.propertyListLocalValue = this.selectedProperty;
                });
              }
            });
          } else {
            if (value) {
              this.changePropertyUuid(value.uuid);
            } else {
              this.changePropertyUuid(null);
            }
          }
        }
      } else {
        console.log('aw 1');
        if (value) {
          console.log('aw 2', value.uuid);
          this.changePropertyUuid(value.uuid);
        } else {
          console.log('aw 3');
          this.changePropertyUuid(null);
        }
      }
    },
    propertyListLocalValueChanged(value) {
      this.propertyListLocalValue = value;
    },
    async changePropertyUuid(uuid) {
      this.$store.commit('v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_BY_UUID', uuid);
      if (uuid == null) {
        this.$store.commit('v2/listingForm/sect1/location/RESTORE_DEFAULT_ADD_STATE');
        this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyDefaultValue');
      } else {
        try {
          this.setLoading(true);
          this.$store.dispatch('v2/listingForm/sect1/openAllHiddenForms');
          await this.$store.dispatch('v2/listingForm/sect1/location/getPropertyDataByUuid');
          this.$store.dispatch(
            'v2/listingForm/sect1/basicInfo/applyRentTypeRule',
            this.selectedPropertyType,
          );
          this.$store.dispatch(
            'v2/listingForm/sect2/detail/applyFacilityRule',
            this.selectedPropertyType,
          );
          this.$store.dispatch(
            'v2/listingForm/sect1/basicInfo/applyRentPeriodRule',
            this.selectedPropertyType,
          );
        } finally {
          this.setLoading(false);
        }
      }
      this.validation.reset();
    },
    propertyTypeClicked(propertyTypeId) {
      if (!this.selectedExistingPropertyUuid) {
        this.$store.commit(
          'v2/listingForm/sect1/location/SET_ACTIVE_PROPERTY_TYPE',
          propertyTypeId,
        );
      }
    },
    addAmenity() {
      this.$store.commit('v2/listingForm/sect1/location/ADD_AMENITY');
      this.$nextTick(function() {
        this.$refs.amenityField[this.amenities.length - 1].$el.querySelector('input').focus();
      });
    },
  },
};
</script>

<style scoped></style>
