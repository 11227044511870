<template>
  <b-popover
    :target="target"
    triggers="hover"
    :placement="placement"
    ref="popover"
    v-if="$mq !== 'xs' && $mq !== 'sm'"
  >
    <div class="row">
      <div class="col-9" v-html="content"></div>
      <div class="col-3">
        <i class="icon mx-2 icon--ideas icon--md"></i>
      </div>
    </div>
  </b-popover>
</template>
<script>
export default {
  props: {
    target: {
      required: true,
      type: String,
      default: 'popover_1',
    },
    content: {
      required: true,
      type: String,
      default: 'No Content...',
    },
    placementOverride: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      placement: 'right',
    };
  },
  mounted() {
    if (this.$mq === 'xs' || this.$mq === 'sm') {
      this.placement = 'bottom';
    }
    if (this.placementOverride) {
      this.placement = this.placementOverride;
    }
  },
};
</script>
