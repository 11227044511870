export default class CheckPropertyNameExist {
  constructor(propertyName, propertyUuid, $http, $i18n) {
    this.propertyName = propertyName;
    this.propertyUuid = propertyUuid;
    this.$http = $http;
    this.$i18n = $i18n;
  }

  get isValidFunc() {
    const validationFunc = async () => {
      const { data } = await this.$http.post('/api/v1/isPropertyNameExist', {
        property_name: this.propertyName,
        propertyUuid: this.propertyUuid,
      });

      if (data.valid === 'false') {
        return this.$i18n.t('errors.addListing.propertyName.exist');
      }
    };

    return validationFunc;
  }
}
